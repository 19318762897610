<template>
  <v-dialog persistent v-model="dialogState_" max-width="650px">
    <v-card class="add-new-property pa-0" height="400px">
      <v-card-title
        class="pa-0 px-5 mb-0 d-flex justify-space-between custom__shadow"
      >
        <h4>وصي جهتك العقارية بالإشتراك في بوابة الأعمال من هكتار</h4>
        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- Details -->
      <v-container class="pb-2">
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="isValid">
            <v-row class="my-5" no-gutters>
              <v-col class="pl-3 mt-2" cols="12" sm="6">
                <v-text-field
                  filled
                  ref="NameInput"
                  v-model="formData.name"
                  placeholder="أدخل إسمك"
                  required
                  min
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="pl-3 mt-2" cols="12" sm="6">
                <v-text-field
                  filled
                  ref="phoneNumberInput"
                  v-model="formData.phoneNumber"
                  placeholder="رقم الهاتف"
                  required
                  min
                  dense
                  hide-details
                  suffix="+966"
                />
              </v-col>
            </v-row>
            <v-row class="my-5" no-gutters>
              <v-col class="pl-3 mt-2" cols="12" sm="6">
                <v-text-field
                  filled
                  ref="phoneNumberInput"
                  v-model="formData.phoneNumber"
                  placeholder="أدخل هاتف الجهة العقارية "
                  required
                  min
                  dense
                  hide-details
                  suffix="+966"
                />
              </v-col>
              <v-col class="pl-3 mt-2" cols="12" sm="6">
                <v-text-field
                  filled
                  ref="NameInput"
                  v-model="formData.name"
                  placeholder="أدخل اسم الجهة العقارية "
                  required
                  min
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <!--temp code-->
            <v-col v-for="i in 5" :key="i"></v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-0 mt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="#2196F3"
            width="135px"
            :loading="isLoading"
            class="ma-0 rounded-7 mt-2 mb-0"
            :disabled="!isValid"
            @click="sendData"
          >
            <span class="my-1 ml-1 font-weight-bold"> إرسال </span>
            <v-icon class="my-auto" size="20">mdi-arrow-left</v-icon>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { userService } from '@/services'

export default {
  name: 'recommendationModel',
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    registration: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {},
      isValid: false,
      isLoading: false,
      phoneNumberRules: [
        (v) => !!v || 'من فضلك ادخل رقم الهاتف',
        (v) => /^[0-9]*$/.test(v) || 'صيغة رقم الهاتف غير صحيحة',
        (v) => (v && v.length === 10) || 'صيغة رقم الهاتف غير صحيحة'
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    dataToSend() {
      const data = {
        phoneNumber: this.formData.phoneNumber.replace('0', '966'),
        name: this.formData.name,
        companyName: this.formData.companyName,
        communicationType: this.formData.communicationType,
        message: this.formData.message
      }
      return data
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closed-add-dialog')
      this.$refs.form.reset()
    },
    async sendData() {
      try {
        this.isLoading = true
        const { data } = await userService.contactSales(this.dataToSend)
        this.addNotification({
          text: `اهلا ومرحبا بك ${data.lead.name} ،تم استلام طلبك. سوف نقوم بالتواصل معك قريبا جدا بهذا الخصوص`,
          color: 'success'
        })
        this.formData = {}
      } catch {
        this.addNotification({
          text: `لقد قمت بالتواصل معنا من قبل، يرجى الانتظار حتى يتم الرد عليك`,
          color: 'error'
        })
      } finally {
        this.isLoading = false
        this.closeDialog()
      }
    }
  },
  created() {
    if (this.user) {
      const { name, company, phoneNumber } = this.user
      this.formData = {
        name,
        phoneNumber: phoneNumber.replace(/966/, 0),
        companyName: company,
        communicationType: this.registration ? 'registration' : ''
      }
    }
    this.formData = {
      communicationType: this.registration ? 'registration' : ''
    }
  }
}
</script>

<style lang="scss">
#contactTextArea {
  margin-top: 0px !important;
}
#contactTextArea::placeholder {
  font-size: 12px !important;
}
</style>

<style lang="scss" scoped>
h5 {
  color: #7a7a7a !important;
}
</style>
